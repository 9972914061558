import React from "react"
import { graphql } from "gatsby"

import {
    Layout,
    useTranslate,
} from "@solublestudio/gatsby-theme-soluble-source"

import BlogPostComponent from "../components/BlogPostComponent"
import BlogFooterComponent from "../components/BlogFooterComponent"
import BlogPageSection from "../components/BlogPageSection"

export default function BlogPost({ data, pageContext }) {
    const translation = useTranslate()

    return (
        <Layout navbarFixed={true} navbarTransparent={true} isDark={false}>
            <BlogPostComponent {...data.post} pageUrl={pageContext.pageUrl} />
            {data.post.related && data.post.related.length ? (
                <BlogPageSection
                    title={translation["blog.post.related"].text}
                    posts={data.post.related}
                    headingTag="h6"
                    blogPostPage={true}
                />
            ) : null}
            <BlogFooterComponent />
        </Layout>
    )
}

export const query = graphql`
    query BlogPost($id: String) {
        post: wpPost(id: { eq: $id }) {
            date(formatString: "MMMM DD, YYYY", locale: "es")
            title
            content_formatted {
                h1
                h2
                h3
                h4
                h5
                img {
                    alt
                    file {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(width: 1280, quality: 100)
                        }
                    }
                }
                ul
                ol
                p
                html
            }
            featuredImage {
                node {
                    localFile {
                        childImageSharp {
                            main: gatsbyImageData(
                                width: 768
                                height: 768
                                transformOptions: { cropFocus: CENTER }
                            )
                            mainDesktop: gatsbyImageData(
                                width: 1280
                                height: 525
                                transformOptions: { cropFocus: CENTER }
                            )
                            seo: resize(width: 1200, height: 630) {
                                src
                            }
                        }
                    }
                    altText
                }
            }
            author {
                node {
                    name
                    avatarImage {
                        publicURL
                    }
                }
            }
            categories {
                nodes {
                    name
                }
            }

            related {
                ...BasicWordpressPost
            }
        }
        allWpPost(sort: { fields: date, order: DESC }, limit: 1) {
            edges {
                node {
                    modified
                }
            }
        }
    }
`
