import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { useStaticQuery, graphql } from "gatsby"

import {
    Container,
    Row,
    Col,
    Heading,
    Text,
    className,
    SocialIcons,
    SectionComponent,
    LineComponent,
} from "@solublestudio/dozen-design-system"

import ContentComponent from "../ContentComponent"

import style from "./style.module.scss"

const BlogPostMeta = ({ date, name, avatarImage }) => (
    <div
        {...className(
            "d-flex align-items-center justify-content-start mb-4 mb-lg-0"
        )}
    >
        <div>
            <div
                {...className(style.avatar)}
                style={{
                    backgroundImage: avatarImage?.publicURL
                        ? `url(${avatarImage.publicURL})`
                        : null,
                }}
            />
        </div>
        <div {...className(`meta d-lg-flex`)}>
            <Text tag="p" className="subtitle" mb={{ xs: 1, lg: 0 }}>
                {name}
            </Text>
            <Text tag="p" className="subtitlels text-uppercase ml-lg-6 mb-lg-0">
                {date}
            </Text>
        </div>
    </div>
)

const BlogPostSocialIcons = ({ pageUrl, title, vertical = false }) => {
    const data = useStaticQuery(graphql`
        query {
            linkedin: file(relativePath: { eq: "linkedin-black.svg" }) {
                publicURL
            }
            twitter: file(relativePath: { eq: "twitter-black.svg" }) {
                publicURL
            }
            facebook: file(relativePath: { eq: "facebook-black.svg" }) {
                publicURL
            }
            mail: file(relativePath: { eq: "mail-black.svg" }) {
                publicURL
            }
        }
    `)

    return (
        <SocialIcons
            padding={1}
            wrapperClassName={vertical ? style["social-icon-vertical"] : ""}
            linkClassName={style["social-icon"]}
            icons={[
                {
                    kind: "facebook",
                    url: `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`,
                    src: data?.facebook?.publicURL,
                    alt: "Dozen Facebook",
                },
                {
                    kind: "twitter",
                    url: `https://twitter.com/home?status=${title} ${pageUrl}`,
                    src: data?.twitter?.publicURL,
                    alt: "Dozen Twitter",
                },
                {
                    kind: "linkedin",
                    url: `https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}&title=&summary=${title}&source=`,
                    src: data?.linkedin?.publicURL,
                    alt: "Dozen Linkedin",
                },
                {
                    kind: "mail",
                    url: `mailto:info@example.com?&subject=&body=${title} ${pageUrl}`,
                    src: data?.mail?.publicURL,
                    alt: "Dozen Email",
                },
            ]}
        />
    )
}

export default function BlogPostComponent({
    date,
    author,
    title,
    content_formatted = [],
    categories = null,
    featuredImage = null,
    pageUrl,
}) {
    return (
        <SectionComponent
            className={`pt-4 pb-8 pt-lg-9 ${style.section} pb-lg-0`}
        >
            <LineComponent className={style.line} />
            <Container className={style.container}>
                {featuredImage?.node?.localFile?.childImageSharp ? (
                    <Row>
                        <Col mb={{ xs: 7, lg: 11 }}>
                            <div className={style.featuredImageWrapper}>
                                <GatsbyImage
                                    {...className("d-lg-none")}
                                    image={getImage(
                                        featuredImage.node.localFile
                                            .childImageSharp.main
                                    )}
                                    alt={title}
                                />
                                <GatsbyImage
                                    {...className("d-none d-lg-block")}
                                    image={getImage(
                                        featuredImage.node.localFile
                                            .childImageSharp.mainDesktop
                                    )}
                                    alt={title}
                                />
                            </div>
                        </Col>
                    </Row>
                ) : null}
                <Row>
                    <Col col={{ lg: 8 }} offset={{ lg: 2 }}>
                        {categories &&
                        categories.nodes &&
                        categories.nodes.length ? (
                            <Text
                                tag="p"
                                className={`subtitlels text-uppercase mb-2 mb-lg-3 ${style.category}`}
                            >
                                {categories.nodes.map((c) => c.name).join(", ")}
                            </Text>
                        ) : null}
                        <Heading tag="h1" className="h2" mb={{ xs: 4 }}>
                            {title}
                        </Heading>
                        <BlogPostMeta
                            date={date}
                            {...(author && author.node ? author.node : {})}
                        />
                    </Col>
                </Row>
                <Row className={style["sticky-row"]}>
                    <Col col={{ lg: 1 }} offset={{ lg: 1 }}>
                        <div
                            {...className(
                                `pt-lg-11 pb-lg-11 pb-lg-11 ${style.sticky}`
                            )}
                        >
                            <BlogPostSocialIcons
                                title={title}
                                pageUrl={pageUrl}
                                vertical={true}
                            />
                        </div>
                    </Col>
                    <Col col={{ lg: 8 }}>
                        <ContentComponent
                            content={content_formatted}
                            wrapperClassName="pt-6 pb-6 pt-lg-11 pb-lg-11"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col
                        offset={{ lg: 2 }}
                        col={{ lg: 10 }}
                        className="d-lg-flex align-items-lg-center"
                    >
                        <BlogPostMeta
                            date={date}
                            {...(author && author.node ? author.node : {})}
                        />
                        <div {...className(`ml-lg-6`)}>
                            <BlogPostSocialIcons
                                title={title}
                                pageUrl={pageUrl}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </SectionComponent>
    )
}
